import { computed, entries, makeObservable, observable } from "mobx";
import { BaseDatastore } from "Frontend/store/base-datastore";
import * as endpoint from "Frontend/generated/CodexEndpoint";
import Codex from "Frontend/generated/de/gemons/core/entities/waitress/mongo/Codex";
import { data_store } from "Frontend/store/data_store";
export class CodexDatastore extends BaseDatastore {
    _entries: Map<string,Codex> = new Map();

    constructor() {
        super()
        makeObservable(this, {
            _entries: observable,
            allEntries: computed,
            ownEntries: computed,
            accessedEntries: computed
        });
    }

    public async init():Promise<void>  {
        await this.fetchAll()
    }

    get allEntries(): Codex[] {
        return Array.from(this._entries.values())
    }

    get ownEntries(): Codex[] {
        return Array.from(this._entries.values()).filter(codex => codex.ownerId === data_store._userId)
    }

    get accessedEntries(): Codex[] {
        return Array.from(this._entries.values()).filter(codex => codex.ownerId !== data_store._userId)
    }

    protected fetch(id: string): void {
        const process_id = this.addToProcessing()
        endpoint.getById(id)
            .then(entry => {
                if (entry) {
                    this.addToData(entry)
                }
            })
            .finally(() => this.removeProcess(process_id))
    }

    protected async fetchAll(): Promise<void> {
        const process_id = this.addToProcessing('codex get for user')
        endpoint.getForUser(
            undefined,
            undefined,
            undefined,
            undefined,
            true
        ).then(
            entries => {
                entries?.forEach(entry =>
                    this.addToData(entry)
                )
        }
        )
            .finally(() => {
                this.removeProcess(process_id)
                return Promise.resolve()
            });
    }

    protected addToData(entry: Codex|undefined) {
        // @ts-ignore
        if (entry?.id != null) {
            // @ts-ignore
            this._entries.set(entry.id, entry)
        }
    }

    saveAll (obj: Codex[]) : void {
        obj.forEach(entry => {
            this.save(entry)
        })
    }

    save (obj: Codex) : Promise<Codex> {
        const process_id = this.addToProcessing()
        return new Promise<Codex>((resolve, reject) => {
            endpoint.save(obj)
                .then(result => {
                    if (result) {
                        this._entries.set(result.id || "", result)
                        resolve(result)
                    }
                })
                .catch(error => reject(error))
                .finally(() => this.removeProcess(process_id))
        })
    }
}

export const codexStore = new CodexDatastore()