import { EndpointRequestInit as EndpointRequestInit_1, Subscription as Subscription_1 } from "@vaadin/hilla-frontend";
import client_1 from "./connect-client.default.js";
import type GroupDateStatus_1 from "./de/gemons/core/entities/waitress/GroupDateStatus.js";
import type PlayerGroup_1 from "./de/gemons/core/entities/waitress/PlayerGroup.js";
import type GroupTimeResponse_1 from "./de/gemons/core/entities/waitress/responses/GroupTimeResponse.js";
async function delete_1(arg0: PlayerGroup_1 | undefined, init?: EndpointRequestInit_1): Promise<void> { return client_1.call("PlayerGroupEndpoint", "delete", { arg0 }, init); }
async function getAllForUser_1(init?: EndpointRequestInit_1): Promise<Array<PlayerGroup_1 | undefined> | undefined> { return client_1.call("PlayerGroupEndpoint", "getAllForUser", {}, init); }
async function getMatchingDaysForGroupAndYearMonth_1(arg0: PlayerGroup_1 | undefined, arg1: unknown, init?: EndpointRequestInit_1): Promise<GroupTimeResponse_1 | undefined> { return client_1.call("PlayerGroupEndpoint", "getMatchingDaysForGroupAndYearMonth", { arg0, arg1 }, init); }
function getPossibleGroupDays_1(arg0: number | undefined, arg1: unknown): Subscription_1<GroupDateStatus_1 | undefined> { return client_1.subscribe("PlayerGroupEndpoint", "getPossibleGroupDays", { arg0, arg1 }); }
async function leaveGroup_1(arg0: PlayerGroup_1 | undefined, init?: EndpointRequestInit_1): Promise<boolean> { return client_1.call("PlayerGroupEndpoint", "leaveGroup", { arg0 }, init); }
async function save_1(arg0: PlayerGroup_1 | undefined, init?: EndpointRequestInit_1): Promise<PlayerGroup_1 | undefined> { return client_1.call("PlayerGroupEndpoint", "save", { arg0 }, init); }
export { delete_1 as delete, getAllForUser_1 as getAllForUser, getMatchingDaysForGroupAndYearMonth_1 as getMatchingDaysForGroupAndYearMonth, getPossibleGroupDays_1 as getPossibleGroupDays, leaveGroup_1 as leaveGroup, save_1 as save };
