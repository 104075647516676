import { computed, makeObservable, observable, when } from "mobx";
import PlayerGroup from "Frontend/generated/de/gemons/core/entities/waitress/PlayerGroup";
import * as endpoint from "Frontend/generated/PlayerGroupEndpoint";
import { BaseDatastore } from "Frontend/store/base-datastore";
import GroupTimeResponse from "Frontend/generated/de/gemons/core/entities/waitress/responses/GroupTimeResponse";

export class PlayergroupDatastore extends BaseDatastore {
    _groups: Map<number,PlayerGroup> = new Map();

    constructor() {
        super()
        makeObservable(this, {
            _groups: observable,
            groups: computed,
            groupIds: computed
        });
    }

    public async init() {
        await this.fetchAll()
    }

    get groups(): PlayerGroup[] {
        return Array.from(this._groups.values()).sort((a, b) => a.name!.localeCompare(b.name!))
    }

    get groupIds(): number[] {
        return Array.from(this._groups.keys())
    }

    protected fetch(id: number): void {
        //Not supported
    }

    protected async fetchAll(): Promise<void> {
        const process_id = this.addToProcessing('playergroup all fetch')
        endpoint.getAllForUser()
            .then(entries => {
                if (entries) {
                    for (let entry of entries) {
                        this.addToData(entry as PlayerGroup)
                    }
                }
            })
            .finally(() => {
                this.removeProcess(process_id)
                return Promise.resolve()
            });
    }

    protected addToData(entry: PlayerGroup) {
        if (entry?.id != null) {
            this._groups.set(entry.id, entry)
        }
    }

    saveAll (obj: PlayerGroup[]) : void {
        obj.forEach((entry) => {
            this.save(entry)
        })
    }

    save (obj: PlayerGroup) : Promise<PlayerGroup> {
        const process_id = this.addToProcessing('playergroup save')
        return new Promise<PlayerGroup>((resolve, reject) => {
            endpoint.save(obj)
                .then(result => {
                    if (result) {
                        this._groups.set(result.id ?? -1, result)
                        resolve(result)
                    }
                })
                .catch(error => reject(error))
                .finally(() => this.removeProcess(process_id))
        })
    }

    delete(obj: PlayerGroup):Promise<boolean> {
        const process_id = this.addToProcessing('playergroup delete')
        return new Promise<boolean>((resolve, reject) => {
            endpoint.delete(obj)
                .then(result => {
                    if (obj.id) {
                        this._groups.delete(obj.id);
                    }
                    resolve(true);
                })
                .catch(error => reject(error))
                .finally(() => this.removeProcess(process_id));
        });
    }

    getDates(group: PlayerGroup, date: Date) {
        return endpoint.getMatchingDaysForGroupAndYearMonth(group, this.formatDateToMonthYear(date))
    }

    getGroupStatus(group: PlayerGroup, date: Date) {
        return endpoint.getPossibleGroupDays(group.id, this.formatDateToMonthYear(date));
    }

    async getDatesAllGroups(date: Date): Promise<Map<string, GroupTimeResponse | undefined>> {
        await when(() => !this.processing)
        const process_id = this.addToProcessing('playergroup get all dates');
        const result = new Map<string, GroupTimeResponse | undefined>();

        // Map all groups to an array of promises
        const datePromises = this.groups.map(async (group) => {
            const r = await this.getDates(group, date);
            if (r) {
                result.set(group.name!, r);
            }
        });

        // Await all promises
        try {
            await Promise.all(datePromises);
            this.removeProcess(process_id);
            return result;
        } catch (e) {
            console.error(e);
            this.removeProcess(process_id);
            throw e;
        }
    }

    async leave(group: PlayerGroup) {
        let result = await endpoint.leaveGroup(group)
        if (result && group.id) {
            this._groups.delete(group.id)
        }
        return result
    }

    private formatDateToMonthYear(date: Date): string {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const monthString = month < 10 ? `0${month}` : `${month}`;
        return `${year}-${monthString}`;
    }

}

export const playerGroupStore = new PlayergroupDatastore()