import { Middleware, MiddlewareContext, MiddlewareNext } from '@vaadin/hilla-frontend';
import {data_store} from "Frontend/store/data_store";
import {heartbeat} from "Frontend/generated/UserEndpoint";

// A middleware is an async function, that receives the `context` and `next`
export const HeartbeatMiddleware: Middleware = async function(
    context: MiddlewareContext,
    next: MiddlewareNext
) {
    console.debug("Request made, resetting heartbeat!")
    if (context.method !== "heartbeat") {
        data_store._userStore.resetHeartBeat()
    }

    return next(context);
}