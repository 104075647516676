import { BaseDatastore } from "Frontend/store/base-datastore";
import { computed, makeObservable } from "mobx";
//@ts-ignore
import FormData from "Frontend/generated/de/gemons/fileservice/entities/FormData";
import * as endpoint from "Frontend/generated/FileEndpoint";

export class FileDatastore extends BaseDatastore {
    _entries: Map<string,File> = new Map();

    constructor() {
        super()
        makeObservable(this, {
            entries: computed
        });
    }

    init() {

    }

    get entries(): File[] {
        return Array.from(this._entries.values())
    }

    public async uploadCodexFile(file: File):Promise<any> {
        let uFile:FormData = {
            data: Array.from(new Uint8Array(await file.arrayBuffer())),
            mimetype: file.type,
            filename: file.name,
        }

        return new Promise((resolve, reject) => {

            endpoint.uploadCodexFile(uFile).then(r => {
                let result = {
                    default: r!
                }
                resolve(result)
            }).catch(e => reject(e))
        })
    }

}

export const fileStore = new FileDatastore()