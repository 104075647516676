import { BaseDatastore } from "Frontend/store/base-datastore";
import * as endpoint from "Frontend/generated/AccountEndpoint";
import { computed, makeObservable, observable } from "mobx";

export class AccountDatastore extends BaseDatastore {
    constructor() {
        super()
        makeObservable(this, {
        });
    }

    public init() {
        // currently nothing
    }

    public updatePassword(newPassword: string, oldPassword:string):Promise<boolean> {
        const process_id = this.addToProcessing()
        return new Promise<boolean>((resolve, reject) => {
            endpoint.changePassword(newPassword, oldPassword)
                .then(r => {
                    if (r) {
                        resolve(r)
                    }
                })
                .catch(error => reject(error))
                .finally(() => this.removeProcess(process_id))
        })
    }

    public updateEMail(newEMail: string, oldPassword:string) {
        const process_id = this.addToProcessing()
        return new Promise<boolean>((resolve, reject) => {
            endpoint.changeEmail(newEMail, oldPassword)
                .then(r => {
                    if (r) {
                        resolve(r)
                    }
                })
                .catch(error => reject(error))
                .finally(() => this.removeProcess(process_id))
        })

    }
}

export const accountStore = new AccountDatastore()