import { getCurrentUserId } from "Frontend/generated/UserEndpoint";
import { PlayergroupDatastore, playerGroupStore } from './playergroup-datastore'
import { TimeruleDatastore, timeRuleStore } from './timerule-datastore'
import { UserDatastore, userStore } from "Frontend/store/user-datastore";
import { CodexEntryDatastore, codexEntryStore } from "Frontend/store/codex-entry-datastore";
import { CodexDatastore, codexStore } from "Frontend/store/codex-datastore";
import Codex from "Frontend/generated/de/gemons/core/entities/waitress/mongo/Codex";
import { AccountDatastore, accountStore } from "Frontend/store/account-datastore";
import { isLoggedIn } from 'Frontend/src/utility/auth'
import { BardDatastore, bardStore } from "Frontend/store/bard-datastore";
import { NotificationDatastore, notificationStore } from "Frontend/store/notification-datastore";
import { FileDatastore, fileStore } from "Frontend/store/file-datastore";
import {LibaryDatastore, libaryStore} from "Frontend/store/libary-datastore";

class DataStore {
    _userId: number = -1;
    _codex: Codex[] = []
    _playergroupStore: PlayergroupDatastore = playerGroupStore;
    _timeruleStore: TimeruleDatastore = timeRuleStore
    _userStore: UserDatastore = userStore
    _codexEntryStore: CodexEntryDatastore = codexEntryStore
    _codexDatastore: CodexDatastore = codexStore
    _accountDatastore: AccountDatastore = accountStore
    _bardDatastore: BardDatastore = bardStore
    _notificationDatastore: NotificationDatastore = notificationStore
    _fileDatastore: FileDatastore = fileStore
    _libaryDatastore: LibaryDatastore = libaryStore


    static async build() {
        if (isLoggedIn()) {
            const store = new DataStore();
            await store.init()
            return store
        }

        return new DataStore()

    }

    private async _loadUserId(){
        getCurrentUserId()
            .then(result => {
                this._userId = result || -1
            })
    }

    get codex() : Codex[] {
        return this._codex
    }

    public async init() {
        this._userStore.init();
        await this._loadUserId();
        await this._playergroupStore.init();
        this._timeruleStore.init();
        await this._codexEntryStore.init();
        await this._codexDatastore.init();
        this._accountDatastore.init();
        this._bardDatastore.init();
        await this._notificationDatastore.init();
        this._fileDatastore.init();
        this._libaryDatastore.init();
    }
}

export const data_store = await DataStore.build()
