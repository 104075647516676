import { BaseDatastore } from "Frontend/store/base-datastore";
import {makeObservable, observable} from "mobx";
import * as endpoint from "Frontend/generated/LibaryEndpoint";
import Codex from "Frontend/generated/de/gemons/core/entities/waitress/mongo/Codex";

export class LibaryDatastore extends BaseDatastore {
    _subscribedCodex: Codex[] = []

    constructor() {
        super()
        makeObservable(this, {
            _subscribedCodex: observable
        });
    }

    public init() {
        this.getSubscribedCodex()
    }

    private getSubscribedCodex() {
        endpoint.getSubscribedCodexList()
            .then(value => {
                this._subscribedCodex = value
            })
            .finally(() => console.debug("Finished getting subscribed codex list"))
    }
}

export const libaryStore = new LibaryDatastore()