import { EndpointRequestInit as EndpointRequestInit_1 } from "@vaadin/hilla-frontend";
import type QuerySortOrder_1 from "./com/vaadin/flow/data/provider/QuerySortOrder.js";
import client_1 from "./connect-client.default.js";
import type Codex_1 from "./de/gemons/core/entities/waitress/mongo/Codex.js";
async function count_1(arg0: string | undefined, arg1: boolean, init?: EndpointRequestInit_1): Promise<number | undefined> { return client_1.call("CodexEndpoint", "count", { arg0, arg1 }, init); }
async function delete_1(arg0: Codex_1 | undefined, init?: EndpointRequestInit_1): Promise<void> { return client_1.call("CodexEndpoint", "delete", { arg0 }, init); }
async function getById_1(arg0: string | undefined, init?: EndpointRequestInit_1): Promise<Codex_1 | undefined> { return client_1.call("CodexEndpoint", "getById", { arg0 }, init); }
async function getForUser_1(arg0: string | undefined, arg1: number | undefined, arg2: number | undefined, arg3: Array<QuerySortOrder_1 | undefined> | undefined, arg4: boolean, init?: EndpointRequestInit_1): Promise<Array<Codex_1 | undefined> | undefined> { return client_1.call("CodexEndpoint", "getForUser", { arg0, arg1, arg2, arg3, arg4 }, init); }
async function save_1(arg0: Codex_1 | undefined, init?: EndpointRequestInit_1): Promise<Codex_1 | undefined> { return client_1.call("CodexEndpoint", "save", { arg0 }, init); }
export { count_1 as count, delete_1 as delete, getById_1 as getById, getForUser_1 as getForUser, save_1 as save };
