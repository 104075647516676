import { computed, makeObservable, observable } from "mobx";
import PlayerTimeRule from "Frontend/generated/de/gemons/core/entities/waitress/timerules/PlayerTimeRule";
import * as endpoint from "Frontend/generated/PlayerTimeRuleEndpoint";
import { BaseDatastore } from "Frontend/store/base-datastore";
import TimeRule from "Frontend/generated/de/gemons/core/entities/waitress/timerules/TimeRule";

export class TimeruleDatastore extends BaseDatastore {
    _entries: Map<Date,PlayerTimeRule> = new Map();
    constructor() {
        super()
        makeObservable(this, {
            _entries: observable,
            timerules: computed
        });
    }

    public init() {
        // Currently nothing
    }

    fetch(date: Date): Promise<TimeRule[]> {
        const process_id = this.addToProcessing()
        this._entries.clear()
        return new Promise<TimeRule[]>((resolve, reject) => {
            endpoint.getForYearMonth(date.getFullYear(), date.getMonth() + 1)
                .then(entries => {
                    if (entries) {
                        for (let entry of entries) {
                            this.addToData(entry as TimeRule)
                        }
                    }
                    resolve(Array.from(this._entries.values()))
                })
                .catch(e => reject(e))
                .finally(() => this.removeProcess(process_id))
        })

    }

    get timerules() {
        return Array.from(this._entries.values())
    }

    public save(rule: TimeRule): Promise<TimeRule> {
        const process_id = this.addToProcessing();
        return new Promise<TimeRule>((resolve, reject) => {
            endpoint.save(rule)
                .then(r => {
                    if (r) {
                        this.addToData(r)
                        resolve(r)
                    }
                })
                .catch(e => reject(e))
                .finally(() => {
                    this.removeProcess(process_id);
                })
        })
    }

    public delete(rule: TimeRule) {
        const process_id = this.addToProcessing();
        endpoint.delete(rule)
            .then(() => {
                //TODO
                //this._entries.delete();
            })
            .catch((error) => {
                console.error("Error deleting time rule:", error);
            })
            .finally(() => {
                this.removeProcess(process_id);
            });
    }

    protected addToData(entry: TimeRule) {
        // @ts-ignore
        if (entry?.id != null) {
            // @ts-ignore
            this._entries.set(entry.id.date, entry)
        }
    }
}

export const timeRuleStore = new TimeruleDatastore()