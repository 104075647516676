import { BaseDatastore } from "Frontend/store/base-datastore";
import * as endpoint from "Frontend/generated/BardEndpoint";
import { makeObservable, observable } from "mobx";
import BardResponse from "Frontend/generated/de/gemons/bard/entities/responses/BardResponse";
import { EndpointError } from "@vaadin/hilla-frontend";
import SimpleQuestBardRequest from "Frontend/generated/de/gemons/bard/entities/requests/SimpleQuestBardRequest";
import CustomParam from "Frontend/generated/de/gemons/bard/entities/data/CustomParam";
import ERequestParam from "Frontend/generated/de/gemons/bard/entities/data/ERequestParam";

export class BardDatastore extends BaseDatastore {
    _responses: BardResponse[] = []

    constructor() {
        super()
        makeObservable(this, {
            _responses: observable
        });
    }

    public init() {
        // currently nothing
    }

    public getSimpleQuest(config?: [any, string, string?][]): Promise<BardResponse> {
        const process_id = this.addToProcessing("bard simple quest")
        return new Promise<BardResponse>((resolve, reject) => {
            endpoint.getQuest(this.generateSimpleQuestRequest(config))
                .then((r) => {
                    this._responses.push(r ?? {})
                    resolve(r ?? {})
                })
                .catch((e: EndpointError) => reject(e))
                .finally(() => this.removeProcess(process_id))
        })
    }
 
    private generateSimpleQuestRequest(config?: [any, string, string?][]):SimpleQuestBardRequest|undefined {
        if (config === undefined || config.length === 0) {
            return undefined
        }
        const params: CustomParam[]= []

        for (const i of config ) {
                params.push({requestParam: i[1] as ERequestParam, string:i[2]})
        }
        return {
            customization: params,
            locale: undefined
        }
    }


}

export const bardStore = new BardDatastore()