import { computed, makeObservable, observable } from "mobx";
import * as endpoint from "Frontend/generated/CodexEntryEndpoint";
import { BaseDatastore } from "Frontend/store/base-datastore";
import CodexEntry from "Frontend/generated/de/gemons/core/entities/waitress/mongo/CodexEntry";

export class CodexEntryDatastore extends BaseDatastore {
    _entries: Map<string, CodexEntry> = new Map();
    _currentCodex: string = "";

    constructor() {
        super()
        makeObservable(this, {
            _entries: observable,
            _currentCodex: observable,
            isCodexSet: computed,
            entries: computed
        });
    }

    public async init() {
        await this.fetchAll()
    }

    get entries(): CodexEntry[] {
        return Array.from(this._entries.values())
    }

    get isCodexSet() {
        return (this._currentCodex !== undefined && this._currentCodex !== "")
    }

    protected fetch(id: string): void {
        const process_id = this.addToProcessing()
        endpoint.getById(id)
            .then(entry => {
                if (entry) {
                    this.addToData(entry)
                }
            })
            .finally(() => this.removeProcess(process_id))
    }

    protected async fetchAll(): Promise<void> {
        // Do nothing
        return Promise.resolve()
    }

    async fetchForCodex(codexId?: string): Promise<void> {
        if (!codexId && this._currentCodex === undefined) {
            this._entries.clear();
            return Promise.reject("No valid id given");
        }
        if (codexId && codexId !== this._currentCodex) {
            this._currentCodex = codexId
        }
        const process_id = this.addToProcessing()
        this._entries.clear()
        return new Promise<void>((resolve, reject) => {
            endpoint.getEntriesForCodex(codexId)
                .then(r => r?.forEach(entry => this.addToData(entry as CodexEntry)))
                .catch(e => reject(e))
                .finally(() => {
                    this.removeProcess(process_id);
                    resolve()
                })
        })
    }

    protected addToData(entry: CodexEntry) {
        if (entry?.id != null) {
            this._entries.set(entry.id, entry)
        }
    }

    saveAll(obj: CodexEntry[]): Promise<CodexEntry[]> {
        const process_id = this.addToProcessing()
        return new Promise<CodexEntry[]>((resolve, reject) => {
            endpoint.saveAll(obj)
                .then(result => {
                    if (result) {
                        result.forEach(r => {
                            if (r) {
                                this._entries.set(r.id ?? "", r)
                            }
                        })
                    }
                    resolve(result as CodexEntry[])
                })
                .catch(error => reject(error))
                .finally(() => this.removeProcess(process_id))
        })
    }

    save(obj: CodexEntry): Promise<CodexEntry> {
        const process_id = this.addToProcessing()
        // Little fallback cleanup. If a node has itself as parentId, remove the parentId
        // Better having a node wrongly on root, than the node and it's children disappear
        if (obj.id === obj.parentEntryId) {
            console.debug(`Parental self-reference prevented for node ${obj.name} with id ${obj.id}`)
            obj.parentEntryId = undefined
        }
        return new Promise<CodexEntry>((resolve, reject) => {
            endpoint.save(obj)
                .then(result => {
                    if (result) {
                        this._entries.set(result.id ?? "", result)
                        resolve(result)
                    }
                })
                .catch(error => reject(error))
                .finally(() => this.removeProcess(process_id))
        })
    }

    delete(obj: CodexEntry): Promise<void> {
        const process_id = this.addToProcessing('Delete Codex Entry')
        return new Promise<void>((resolve, reject) => {
            endpoint.delete(obj)
                .then(async r => {
                    if (obj.id) {
                        this._entries.delete(obj.id)
                    }
                    for (const child of this._getFilteredEntriesByParentItem(obj.id)) {
                        await this.delete(child);
                    }
                    resolve(r)
                })
                .catch(e => reject(e))
                .finally(() => this.removeProcess(process_id))
        })
    }

    public updateDataprovider(entries: CodexEntry | CodexEntry[]): void {
        const e: CodexEntry[] = new Array().concat(entries)
        e.forEach(x => this._entries.set(x.id as string, x))
    }

    public _getAllParents(item: CodexEntry): CodexEntry[] {
        const allEntries = [...this.entries.values()];

        const result = allEntries.find(entry => entry.id === item.parentEntryId)
        if (result === undefined) return []

        const parents = this._getAllParents(result)

        return parents.concat(result)
    }

    public _getFilteredEntriesByParentItem(parentItem: string | undefined): CodexEntry[] {
        const allEntries = [...this.entries.values()];

        // Filter chapters by parentItemId if provided, otherwise get root chapters
        const filteredEntries = parentItem
            ? allEntries.filter((entry) => entry.parentEntryId === parentItem)
            : allEntries.filter((entry) => entry.parentEntryId === undefined);

        filteredEntries.sort((a, b) => a.orderIndex - b.orderIndex);

        filteredEntries.forEach(f => {
            f.hasChildren = allEntries.some(e => e.parentEntryId === f.id);
        })

        return filteredEntries;
    }

    public _getEntriesForCodex(page: number, pageSize: number, parentItem: string | undefined): [CodexEntry[], number] {
        const filteredEntries = this._getFilteredEntriesByParentItem(parentItem)
        // Calculate the size of all possible results
        const size = filteredEntries.length;

        // Paginate the results based on page and pageSize
        const startIndex = page * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedResults = filteredEntries.slice(startIndex, endIndex);

        return [paginatedResults, size];
    }

    public isXthParent(targetParentNode: CodexEntry, currentNode?: CodexEntry): [boolean, CodexEntry?] {
        return this.isXthParentProblem(targetParentNode, true, currentNode);
    }

    private isXthParentProblem(targetParentNode: CodexEntry, initial: boolean, currentNode?: CodexEntry): [boolean, CodexEntry?] {
        if (currentNode === undefined ) {
            return [false, undefined]
        }
        if (currentNode.parentEntryId === targetParentNode.id) {
            if (initial){
                return [false, undefined]
            }
            return [true, currentNode]
        }
        if (currentNode.parentEntryId === undefined) {
            return [false, undefined];
        }
        return this.isXthParentProblem(targetParentNode, false, this._entries.get(currentNode.parentEntryId || ""))
    }

    public async duplicateEntry(entry: CodexEntry, newParentId?: string): Promise<CodexEntry[]> {
        if (!entry) []

        const children: CodexEntry[] = this._getFilteredEntriesByParentItem(entry.id)
        const result: CodexEntry[] = []

        let clonedEntry: CodexEntry = {...entry};
        clonedEntry.id = undefined
        clonedEntry.name += ' - Copy'

        if (newParentId) clonedEntry.parentEntryId = newParentId

        return new Promise<CodexEntry[]>((resolve, reject) => {
            this.save(clonedEntry).then(async r => {
                for (const child of children) {
                    result.concat(await this.duplicateEntry(child, r.id));
                }
            })
            resolve(result)
        })
    }

    /**
     * Given node gets as parent the parents' parent
     */
     public async inheritParent(node: CodexEntry) {
        const children = this.entries.filter(entry => entry.parentEntryId === node.id);
        for (const child of children) {
            child.parentEntryId = node.parentEntryId
        }
        await this.saveAll(children);
    }

    public async getFeedItems( queryText:String ): Promise<any> {
        // As an example of an asynchronous action, return a promise
        // that resolves after a 100ms timeout.
        // This can be a server request or any sort of delayed action.
        return new Promise( resolve => {
            setTimeout( () => {
                const itemsToDisplay = [... this._entries.values()]
                    // Filter out the full list of all items to only those matching the query text.
                    .filter( (item: CodexEntry) => item.name!.toLowerCase().includes( queryText.toLowerCase() ) )
                    // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
                    .slice( 0, 10 )
                    .map((item: CodexEntry) => ({id: '@'+item.id, text: item.name, codex: this._currentCodex, node: item.id}));

                resolve( itemsToDisplay );
            }, 100 );
        } );
    }
}

export const codexEntryStore = new CodexEntryDatastore()