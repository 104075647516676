import { EndpointRequestInit as EndpointRequestInit_1 } from "@vaadin/hilla-frontend";
import client_1 from "./connect-client.default.js";
import type Notification_1 from "./de/gemons/core/entities/waitress/Notification.js";
import type User_1 from "./de/gemons/core/entities/waitress/User.js";
async function accept_1(arg0: Notification_1 | undefined, init?: EndpointRequestInit_1): Promise<void> { return client_1.call("NotificationEndpoint", "accept", { arg0 }, init); }
async function addFriend_1(arg0: User_1 | undefined, init?: EndpointRequestInit_1): Promise<void> { return client_1.call("NotificationEndpoint", "addFriend", { arg0 }, init); }
async function decline_1(arg0: Notification_1 | undefined, init?: EndpointRequestInit_1): Promise<void> { return client_1.call("NotificationEndpoint", "decline", { arg0 }, init); }
async function getForUser_1(init?: EndpointRequestInit_1): Promise<Array<Notification_1 | undefined> | undefined> { return client_1.call("NotificationEndpoint", "getForUser", {}, init); }
async function inviteToCodex_1(arg0: number | undefined, arg1: string | undefined, init?: EndpointRequestInit_1): Promise<void> { return client_1.call("NotificationEndpoint", "inviteToCodex", { arg0, arg1 }, init); }
async function save_1(arg0: Notification_1 | undefined, init?: EndpointRequestInit_1): Promise<Notification_1 | undefined> { return client_1.call("NotificationEndpoint", "save", { arg0 }, init); }
export { accept_1 as accept, addFriend_1 as addFriend, decline_1 as decline, getForUser_1 as getForUser, inviteToCodex_1 as inviteToCodex, save_1 as save };
