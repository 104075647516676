import  { v4 as uuid } from 'uuid';
import { action, computed, makeObservable, observable } from "mobx";
export abstract class BaseDatastore {
    public _processes:Map<string, string> = observable(new Map());

    protected constructor() {
        makeObservable(this, {
            _processes: observable,
            processing: computed,
            addToProcessing: action,
            removeProcess: action
        });
    }

    get processing(): boolean {
        return this._processes.size !== 0;
    }

    addToProcessing(source?: string): string {
        const id = uuid();
        this._processes.set(id, source ?? '');
        //console.log('added...', id)
        //console.log('state', Array.from(this._processes.entries()))
        return id;

    }

    removeProcess(id: string) {
        //console.log('removed', id)
        this._processes.delete(id)
        //console.log('state', Array.from(this._processes.entries()))
    }

    public async simulate_process(duration:number) {
        const process_id = this.addToProcessing()
        await new Promise(f => setTimeout(f, duration)).finally(() => this.removeProcess(process_id));
    }
}